@import '~bootstrap/scss/bootstrap';
@import-normalize;

$screen-new-handheld-max: 361px;
$screen-old-handheld-max: 320px;
$screen-desktop: 840px;

@mixin new-handheld {
  @media (max-width: #{$screen-new-handheld-max}) {
      @content;
  }
}

@mixin new-handheld-up {
  @media (min-width: #{$screen-new-handheld-max}) {
      @content;
  }
}

@mixin old-handheld {
  @media (max-width: #{$screen-old-handheld-max}) {
      @content;
  }
}

@mixin old-handheld-up {
  @media (min-width: #{$screen-old-handheld-max}) {
      @content;
  }
}

@mixin desktop {
  @media (max-width: #{$screen-desktop}) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: #{$screen-desktop}) {
    @content;
  }
}

input.form-password {
  // font-family: "password";
  text-security: disc;
  -webkit-text-security: disc;
  -moz-text-security: disc; 
}

/* generic */
h1, h2, h3, h4, h5, h6, p {
  padding: 0;
  margin: 0;
}

.background-lightblue {
  background-color: lightblue;
}

.background-salmon {
  background-color: salmon;
}

.hidden {
  display: none;
}

.color-red {
  color: red;
}

.color-lightgreen {
  color: lightgreen;
}

.text-align-center {
  text-align: center;
}

.margin-auto {
  margin: 0 auto;
}

.default-alignment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.header {
  color: #f1f1f1;
  background-color: #3581df;
  font-weight: bold;
  padding: 5px 0;
  text-align: center;
}

.home-button-list {
  padding: 10px 0;
  align-items: center;
}

.handheld-body-container,
.desktop-body-container,
.desktop-incomingdeliveriesbody-container {
  min-height: 360px;
  padding: 5px;
  margin: 0 auto;
}

.handheld-body-container {
  min-width: 320px;
  max-width: 361px;

  width: 100%;
}

.error-message {
  color: red;
}


/* Sign In */
.signin-table {
  width: 100%;

  border-collapse: collapse;

  font-size: 20px;
}

.signin-table tr {
  height: 2em;

  td {
    div:not(.checkbox-row) {
      display: flex;
      flex-direction: columm;
      align-items: center;

      p {
        font-size: 1rem;
        margin: 0;
        padding: 0;
      }

      > :first-child {
        flex: 0 0 30%;
      }

      > :nth-child(2) {
        flex: 0 0 70%;
      }
    }
  }
}

.signin-table input,
.signin-login-button {
  width: 100%;
  border-radius: 5px;

  border: 1px solid black;

  padding: 5px 10px;
  
  font-size: 16px;
  font-weight: 600;

}

.signin-login-button {
  background-color: #3581df;

  color: white;
}

.btnHover:hover {
  cursor: pointer;
}


